import React from 'react';

export default React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    // handleHidden(e) {
    //   transformer.current.hide()
    // },
  }))
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch(`https://www.stickers-garage.com/modules/stickers_express/json.php?width=${props.width}&height=${props.height}`)
      .then((response) => response.json())
      .then((json) => {props.setDataPrice(json.items.reverse());setData(json.items.reverse())})
      .catch((error) => console.error(error))
  }, []);

  return (
        <div className="price">
        {
          data ?
            data.map((item, i) => {
              return <div key={i} className="tiers">
                <p onClick={()=>props.setQuantityFromPrice(item.product_quantity)}>{item.product_quantity}pcs</p>
                <p onClick={()=>props.setQuantityFromPrice(item.product_quantity)}>{item.price}€</p>
                <p onClick={()=>props.setQuantityFromPrice(item.product_quantity)}>-{item.discount}%</p>
              </div>
            })
          : null
        }
        </div>
  )
})
