import React, {useState, useEffect} from 'react';
import './../assets/style/Modal.css';
import { BiSearchAlt } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
import { SpinnerComponent } from 'react-element-spinner';


const Modal = ({ show, close, addImage}) => {

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleAddImage = (src) => {
    addImage(src)
  }

  useEffect( () => {
    fetch(`https://www.stickers-garage.com/modules/stickers_express/library/search.php?q=a`)
      .then((response) => response.json())
      .then((json) => {
        if (json.items){
          setData(json.items)
        } else {
          setData("Aucun résultat trouvé")
        }
      })
      .catch((error) => console.error(error))
  }, []);

const fetchSearch = () => {
    fetch(`https://www.stickers-garage.com/modules/stickers_express/library/search.php?q=${searchTerm}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.items){
            if(json.items.length > 0){
              setData(json.items)
            } else {
              setData("Aucun résultat trouvé")
            }
          } else {
            setData("Aucun résultat trouvé")
          }
        })
        .catch((error) => console.error(error))
  }
const search = (e) => {
  if (e.charCode === 13) {
    fetchSearch()
  }
}

  return (
    <>
    {
    show ?

    <div className="modalContainer">
        <div className="input3" style={{overflowX: 'hidden'}}>
          <ImCross className="cross" onClick={() => close()}/>
          <div className="container-search">
            <input type='search'  className='search' placeholder='Rechercher' onKeyPress={search} onChange={(e =>{
              setSearchTerm(e.target.value)
            })} />
            <button className="button-search" onClick={fetchSearch}><BiSearchAlt/></button>
          </div>
          {
            data === "Aucun résultat trouvé"?
              <h2>{data}</h2>
              :<>
              {
                data ?
                  data.map((image,key) =>(
                    <div key={key} className="imagee">
                      <img key={key} src={image.img} alt={image.name} onClick={() => handleAddImage(image.img)}/>
                    </div>
                  ))
                : <SpinnerComponent loading={true} position="centered" />
              }
              </>
          }
        </div>
    </div>
      : null
    }
    </>
  );
};

export default Modal;
