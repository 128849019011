import React, {useState, useEffect} from 'react';
import { HexColorPicker } from "react-colorful";
import ReactDOM from 'react-dom';
import { AiFillCaretDown, AiFillSave } from 'react-icons/ai';
import { BiText, BiRefresh } from 'react-icons/bi';
import { FaGrinStars } from 'react-icons/fa';
import { ImCross, ImUpload2 } from 'react-icons/im';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import './assets/style/App.sass';
import Canvas from './canvas.js';
import Price from './components/price.js';
import FontPicker from "font-picker-react";
import Popup from "reactjs-popup";
import Modal from './components/modal';

let dataText = []
let ContentModal = null

// CONFIG ///
const listMaterials = window.ENV.listMaterials
const listFormes = window.ENV.listFormes
const minSize = window.ENV.minSize
const maxSize = window.ENV.maxSize
// CONFIG ///


const App = () => {
  // Setup //
  const [materials, setMaterials] = React.useState(0);
  const [ratio, setRatio] = React.useState([0, 'width']);
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [form, setForm] = React.useState(3);
  const [quantity, setQuantity] = React.useState(1);
  const [color, setColor] = React.useState("#ffffff");
  const [activeFontFamily, setActiveFontFamily] = React.useState("Arimo");
  const [allText, setAllText] = React.useState([]);
  const [colorText, setColorText] = React.useState("#000000");
  const [allImages, setAllImages] = React.useState([]);
  const [myPrice, setMyPrice] = React.useState(0);
  const [allPrice, setAllPrice] = React.useState([]);
  // Setup //
  // Menu materials //
  const [show, setShow] = React.useState(false);
  const [contentm, setContentm] = React.useState(null);
  const [positionMaterials, setPositionMaterials] = React.useState(`-${((listMaterials.length + 1)*13)+2}vw`);
  const dropDown = React.useRef(null)
  const back = React.useRef(null)

  React.useEffect(() => {
    if (hei.current) {
      hei.current.value = height || minSize
    }
    checkPrice(quantity)
  }, [width]);
  React.useEffect(() => {
    if (wid.current) {
      wid.current.value = width || minSize
    }
    checkPrice(quantity)
  }, [height])

  React.useEffect(() => {
    if (qty.current){
      qty.current.value = quantity
    }
    checkPrice(quantity)
  }, [quantity])

  React.useEffect(() => {
    document.addEventListener('mousedown', (e) =>{
      if (dropDown.current &&
          dropDown.current.children[0].style.marginTop === "0px" &&
          !dropDown.current.contains(e.target) &&
          !back.current.contains(e.target)) {
        back.current.click()
      }
    })
  }, []);
  const setQuantityFromPrice = (qty) =>{
    setQuantity(qty)
  }
  const setDataPrice = (data) => {
    setAllPrice(data)
    setTimeout(function () {
      checkPrice(quantity)
    }, 100);
  }
  function checkPrice(qty) {
    var price = 0
    allPrice.forEach((item, i) => {
      if (qty > item.product_quantity){
        price = allPrice[i+1].price
      } else if (item.active){
        price = item.price
      }
    });
    setMyPrice(price)
  }
  const handleClick = (e) => {
    e.preventDefault();
    setShow(!show);
    if (!show) {
      setPositionMaterials(0);
      dropDown.current.style.display =  "block"
      document.querySelector('.down').style.transform = 'rotate(180deg)'
    } else {
      setPositionMaterials(`-${((listMaterials.length + 1)*13)+2}vw`);
      document.querySelector('.down').style.transform = 'rotate(0deg)'
      setTimeout(function () {
        if (document.querySelector('.down').style.transform === 'rotate(0deg)'){
          dropDown.current.style.display =  "none"
        }
      }, 500);
    }
  }
  const handleRadio = (ev) => {
    listMaterials.forEach((item, i) => {
      if (ev === item) {
        setMaterials(i)
      }
    });
  }
  // Menu materials //
  // Modal //
  const [openMadal, setOpenMadal] = React.useState(false);
  const handleCloseModal = () => {
    setOpenMadal(false)
    ContentModal = null
  }
  const getContent = () => {
    return ContentModal
  }
  // Modal //
  // forme //
  const handleChangeForme = (e, name) => {
    listFormes.forEach((item, i) => {
      if (name === item[0]) {
        setForm(i)
        setTimeout(function () {
          convas.current.handleChangeForm(i)
        }, 10);
        if (i < 3 && !sizeScreen) {
          setOpenMadal(false)
        }
        Array.from(e.target.parentElement.parentElement.children).forEach((item, i) => {
          item.style.backgroundColor = ""
          item.style.color = ""
        });
        e.target.parentElement.style.backgroundColor = "#5B40D7"
        e.target.parentElement.style.color = "#fff"
      }
    });
  }
  const handleForme = () => {
    setOpenMadal(true)
    ContentModal = <div className="label">
      <h4>Forme</h4>
      {
        listFormes.map((item, key) =>
          form === key ?
            <label key={key} style={{backgroundColor: '#5B40D7', color: "#fff"}}>
              <input type="radio" name="forme" onClick={(e)=>{handleChangeForme(e, item[0])}}/>
              <img src={`./assets/images/${item[1]}`} alt={item[0]}/>
              <p>{item[0]}</p>
            </label>
          :
            <label key={key}>
              <input type="radio" name="forme" onClick={(e)=>{handleChangeForme(e, item[0])}}/>
              <img src={`./assets/images/${item[1]}`} alt={item[0]}/>
              <p>{item[0]}</p>
            </label>
        )
      }
    </div>
    setContentm(ContentModal)
  }
  // forme //
  // Hauteur //
  const hei = React.useRef(null)
  const handleHeight = () => {
    setOpenMadal(true)
    ContentModal = <div className="input">
      <h4>Hauteur</h4>
      <input ref={hei} type="number" onChange={handleChangeHeight}/>
    </div>
    setContentm(ContentModal)
    setTimeout(() => { return (hei ? hei.current.value = height : null) }, 10);
  }
  const handleChangeHeight = (e) => {
    var value = Number(e.target.value).toFixed(1)
    var secondeValue = Number(e.target.value).toFixed(1)
    if (Math.sign(value) === -1){
      value = 0
      e.target.value = value
    }
    if (ratio[1] === 'width') {
      secondeValue = Number(secondeValue * ratio[0]).toFixed(1)
    } else {
      secondeValue = Number(secondeValue / ratio[0]).toFixed(1)
    }
    if (value < minSize || secondeValue < minSize){return}
    if (value > maxSize || secondeValue > maxSize){return}
    setHeight(value)
    setWidth(secondeValue)
  }

  // Hauteur //
  // Largeur //
  const wid = React.useRef(null)
  const handleWidth = () => {
    setOpenMadal(true)
    ContentModal = <div className="input">
      <h4>Largeur</h4>
      <input ref={wid} type="number" onChange={handleChangeWidth}/>
    </div>
    setContentm(ContentModal)
    setTimeout(() => { return (wid ? wid.current.value = width : null) }, 10);
  }
  const handleChangeWidth = (e) => {
    var value = Number(e.target.value).toFixed(1)
    var secondeValue = Number(e.target.value).toFixed(1)
    if (Math.sign(value) === -1){
      value = 0
      e.target.value = value
    }
    if (ratio[1] === 'width') {
      secondeValue = Number(secondeValue / ratio[0]).toFixed(1)
    } else {
      secondeValue = Number(secondeValue * ratio[0]).toFixed(1)
    }
    if (value < minSize || secondeValue < minSize){return}
    if (value > maxSize || secondeValue > maxSize){return}
    setHeight(secondeValue)
    setWidth(value)
  }
  // Largeur //
  // Quantité //
  const qty = React.useRef(null)
  const handleQuantity = () => {
    setOpenMadal(true)
    ContentModal = <div className="input">
      <h4>Quantité</h4>
      <input ref={qty} type="number" onChange={handleChangeQuantity}/>
      <div className='change'>
        <IoMdAddCircle onClick={handleAdd}/>
        <IoMdRemoveCircle onClick={handleRemove}/>
      </div>
    </div>
    setContentm(ContentModal)
    setTimeout(() => { return (qty ? qty.current.value = quantity : null) }, 10);
  }
  const handleChangeQuantity = (e) => {
    var value = Number(e.target.value).toFixed(0)
    if (Math.sign(value) === -1) {
      value = 1
      e.target.value = value
    }
    setQuantity(value)
    checkPrice(value)
  }
  const handleAdd = () => {
    setQuantity(Number(quantity) + 1)
    if (qty.current){
      qty.current.value = Number(quantity) + 1
    }
    checkPrice(Number(quantity) + 1)
  }
  const handleRemove = () => {
    if (qty.current){
      if (qty.current.value > 1){
        setQuantity(Number(qty.current.value).toFixed(0) - 1)
        qty.current.value -= 1
      }
      checkPrice(qty.current.value)
    } else {
      if (quantity > 1){
        setQuantity(Number(quantity).toFixed(0) - 1)
        checkPrice(Number(quantity).toFixed(0) - 1)
      }
    }
  }
  // Quantité //
  // Texte //
  const txt = React.useRef(null)
  var valueFont = 'Arimo';
  const handleText = () => {
    setOpenMadal(true)
    ContentModal = <div className="input" style={{overflow: 'hidden'}}>
      <h4>Insérer texte</h4>
      <textarea className="textarea" ref={txt} onKeyDown={()=> {
        var myelem = document.querySelector('.dropdown-font-family')
        if (myelem.getAttribute("focused")  === "true"){
          dataText[myelem.getAttribute("index")] = {text: txt.current.value, color: document.querySelector('.dropdown-font-family').getAttribute("color"), font: valueFont}
          setAllText([...dataText])
        }}}></textarea>
      <FontPicker
					apiKey="AIzaSyBYOh12Dt84628EaMhOPuv-o3l3TXFLWfw"
					activeFontFamily={activeFontFamily}
					onChange={(nextFont) =>{
            var myelem = document.querySelector('.dropdown-font-family')
            setActiveFontFamily(nextFont.family)
            myelem.innerHTML = nextFont.family
            myelem.style.fontFamily = nextFont.family
            valueFont = nextFont.family
            if (myelem.getAttribute("focused") === "true"){
              dataText[myelem.getAttribute("index")] = {text: txt.current.value, color: document.querySelector('.dropdown-font-family').getAttribute("color"), font: valueFont}
              setAllText([...dataText])
            }
          }
					}
				/>
      <button className="add-text" onClick={handleCreateText}>Ajouter</button>
      <HexColorPicker color={color} onChange={handleChangeColorText} />
    </div>
    setContentm(ContentModal)
  }

  const handleChangeColorText = (color) => {
    var myelem = document.querySelector('.dropdown-font-family')
    setColorText(color)
    document.querySelector('.dropdown-font-family').setAttribute('color', color)
    if (myelem.getAttribute("focused")  === "true"){
      dataText[myelem.getAttribute("index")] = {text: txt.current.value, color: document.querySelector('.dropdown-font-family').getAttribute("color"), font: valueFont}
      setAllText([...dataText])
    }
    // txt.current.style.color = color
  }
  const handleCreateText = () => {
    dataText = [...dataText, {text: txt.current.value, color: document.querySelector('.dropdown-font-family').getAttribute("color"), font: valueFont}]
    setAllText([...dataText])
    if (!sizeScreen){
      setOpenMadal(false)
    }
  }
  const handleDeleteMe = (i) => {
    dataText[i] = null
    setAllText([...dataText])
  }
  const focusText = (i) => {
    if (document.querySelector('.dropdown-font-family')){
      setTimeout(function () {
        document.querySelector('.dropdown-font-family').innerHTML = dataText[i].font
        document.querySelector('.dropdown-font-family').style.fontFamily = dataText[i].font
        valueFont = dataText[i].font
        txt.current.value = dataText[i].text
        document.querySelector('.dropdown-font-family').setAttribute('focused', 'true')
        document.querySelector('.dropdown-font-family').setAttribute('index', i)
      }, 10);
    }
  }
  const focusOutText = () => {
    if (document.querySelector('.dropdown-font-family')){
      document.querySelector('.dropdown-font-family').setAttribute('focused', 'false')
      txt.current.value = ''
    }
  }
  // Texte //
  // couleur //
  const col = React.useRef(null)
  const handleColor = () => {
    setOpenMadal(true)
    ContentModal = <div className="input" style={{overflow: 'hidden'}}>
      <h4>Couleur fond</h4>
      <HexColorPicker color={color} onChange={handleChangeColor} />
      <div ref={col} className="colorShow"></div>
    </div>
    setContentm(ContentModal)
  }
  const handleChangeColor = (color) => {
    setColor(color)
    col.current.style.backgroundColor = color
  }
  // couleur //

  const containCanvas = React.useRef(null)

  React.useEffect(() => {
    document.querySelector('canvas').width = containCanvas.current.offsetWidth
    document.querySelector('canvas').height = containCanvas.current.offsetHeight
    fetch(`https://www.stickers-garage.com/modules/stickers_express/json.php?width=3&height=2.8`)
      .then((response) => response.json())
      .then((json) => {setDataPrice(json.items.reverse())})
      .catch((error) => console.error(error))
  }, []);
  const convas = React.useRef(null)
  const [sizeScreen, setSizeScreen] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 600){
      setSizeScreen(false);
    } else {
      setSizeScreen(true);
    }

    window.addEventListener('resize', (e) => {
      if (window.innerWidth < 600){
        setSizeScreen(false);
      } else {
        setSizeScreen(true);
      }
    });
  }, []);

  const [modal, setModal] = useState(false);
  if (openMadal && modal){
    setModal(false)
  }
  const Toggle = () =>{
    if (!modal){
      setOpenMadal(false)
    }
    setModal(!modal)
  };
  const addImage = (src) => {
    setAllImages([...allImages, {src, x:0, y:0, width:100, height:100}])
  }
  const handleDeleteMeImage = (i) => {
    var data = allImages
    data[i] = null
    setAllImages([...data])
  }
  const moveImage = (myData) => {
    var data = allImages
    data[myData.index].x = myData.x
    data[myData.index].y = myData.y
    setAllImages([...data])
  }

  const handleMateriaux = () => {
    setOpenMadal(true)
    ContentModal =
    <div className="labelMat">
      <h4>Matériaux</h4>
      {
        listMaterials.map((item, key) =>
          <label key={key}>
            <img src={`./assets/images/${item[1]}`} alt={item[0]}/>
            {
              item === listMaterials[materials][0] ?
                <input type="radio" name="meterial" defaultChecked/>
              :
                <input type="radio" name="meterial" onClick={() => handleRadio(item)}/>
            }
            <p>{item[0]}</p>
          </label>
        )
      }
    </div>
    setContentm(ContentModal)
  }

  return (
    <>
      {sizeScreen
      ?
      (
        <div className="App">
          <div className="editor">
            <div className="myCanvas" ref={containCanvas}>
              <p className="widthSize">{height} cm</p>
              <p className="heightSize">{width} cm</p>
              <div className="myCanvas" ref={containCanvas}>
                <Canvas
                  ref={convas}
                  containCanvas={containCanvas}
                  minSize={minSize}
                  maxSize={maxSize}
                  setWidth={setWidth}
                  setHeight={setHeight}
                  setRatio={setRatio}
                  form={form}
                  font={activeFontFamily}
                  colorText={colorText}
                  color={color}
                  height={height}
                  width={width}
                  ratio={ratio}
                  allText={allText}
                  allImages={allImages}
                  handleDeleteMe={handleDeleteMe}
                  handleDeleteMeImage={handleDeleteMeImage}
                  moveImage={moveImage}
                  sizeScreen={sizeScreen}
                  materials={materials}
                  focusText={focusText}
                  focusOutText={focusOutText}
                />
              </div>
              <button className="refresh" onClick={(e) => {convas.current.handleRefreshOutline()}}>
                <BiRefresh/>
              </button>
            </div>
          </div>
          <div className="column-right">
            <Modal show={modal} title="My Modal" close={Toggle} addImage={addImage}/>
            {
            openMadal ?
              <div className="modal">
                <div className="content">
                  <ImCross className="cross" onClick={handleCloseModal}/>
                  { getContent() }
                  <button className="done" onClick={handleCloseModal}>Terminer</button>
                </div>
              </div>
            : null
          }
            <div className="option-right">
              <div className="params">
                <button className="width">
                  <h5>Largeur</h5>
                  <input ref={wid} type="number" onChange={handleChangeWidth}/> cm
                </button>
                <button className="height">
                  <h5>Hauteur</h5>
                  <input ref={hei} type="number" onChange={handleChangeHeight}/> cm
                </button>
                <Popup
                  trigger={<button className="quantity">
                    <h5>Quantité</h5>
                    <div>
                      <IoMdRemoveCircle className="iconQty" onClick={handleRemove}/>
                      <IoMdAddCircle className="iconQty" onClick={handleAdd}/>
                      <input className="inputqty" ref={qty} type="number" onChange={handleChangeQuantity}/>
                    </div>
                  </button>}
                  position="top center"
                  on="hover"
                >
                  <Price
                    width={width}
                    height={height}
                    setDataPrice={setDataPrice}
                    setQuantityFromPrice={setQuantityFromPrice}
                    />
                </Popup>
              </div>
              <div className="final-price">
                <p>Total:</p>
                <p>{myPrice} €</p>
              </div>
              <button className="add-panier" onClick={(e) => convas.current.handleDownload(e, 'pdf')}>
                Ajouter au panier
              </button>
            </div>
          </div>
          <div className="column-extrem">
            <label htmlFor="import">
            <div>
              <ImUpload2 /> <p>Importer Image</p>
              <input id="import" type="file" onChange={(e) => {convas.current.handleChangeImage(e)}}/>
            </div>
            </label>
            <div>
              <button className="form" onClick={handleForme}>
                <h5>Forme</h5>
                <img src={`./assets/images/${listFormes[form][1]}`} alt={listFormes[form][0]}/>
                <p>{listFormes[form][0]}</p>
              </button>
            </div>
            <div>
              <button className="text" onClick={handleText} >
                <h5>Insérer<br/>Texte</h5>
                <BiText/>
              </button>
            </div>
            <div>
              <button className="background" onClick={handleColor}>
                <h5>Couleur<br/>Fond</h5>
                <div className="myColor" style={{backgroundColor: color}}></div>
              </button>
            </div>
            <div>
              <button className="image" onClick={() => Toggle()}>
                <h5>Librairie<br/>d'image</h5>
                <FaGrinStars/>
              </button>
            </div>
            <div>
              <button className="materiaux" onClick={handleMateriaux}>
                <h5>Matériaux :</h5>
                <img src={`./assets/images/${listMaterials[materials][1]}`} alt={listMaterials[materials][0]}/>
                <p>{listMaterials[materials][0]}</p>
              </button>
            </div>
          </div>
        </div>
      )
      :
      (
          <div className="App">
          <Modal show={modal} title="My Modal" close={Toggle}/>
        {
          openMadal ?
            <div className="modal">
              <div className="content">
                <ImCross className="cross" onClick={handleCloseModal}/>
                { contentm }
                <button className="done" onClick={handleCloseModal}>Terminer</button>
              </div>
            </div>
          : null
        }
        <div className="materials">
          <button ref={back} onClick={handleClick}>
            <span>Matériaux:</span> {listMaterials[materials][0]}
            <AiFillCaretDown className="down"/>
          </button>
          <div className="menuMaterials" ref={dropDown} style={{height: `${((listMaterials.length + 1)*13)+2}vw`}}>
            <div className="content" style={{marginTop: positionMaterials}}>
              {
                listMaterials.map((item, key) =>
                  <label key={key}>
                    <img src={`./assets/images/${item[1]}`} alt={item[0]}/>
                    {
                      item === listMaterials[materials][0] ?
                        <input type="radio" name="meterial" defaultChecked/>
                      :
                        <input type="radio" name="meterial" onClick={() => handleRadio(item)}/>
                    }
                    <p>{item[0]}</p>
                  </label>
                )
              }
            </div>
          </div>
        </div>
        <div className="editor">
          <p className="widthSize">{height} cm</p>
          <p className="heightSize">{width} cm</p>
          <div className="myCanvas" ref={containCanvas}>
            <Canvas
              ref={convas}
              containCanvas={containCanvas}
              minSize={minSize}
              maxSize={maxSize}
              setWidth={setWidth}
              setHeight={setHeight}
              setRatio={setRatio}
              form={form}
              font={activeFontFamily}
              colorText={colorText}
              color={color}
              height={height}
              width={width}
              ratio={ratio}
              allText={allText}
              allImages={allImages}
              handleDeleteMe={handleDeleteMe}
              handleDeleteMeImage={handleDeleteMeImage}
              moveImage={moveImage}
              sizeScreen={sizeScreen}
              materials={materials}
              focusText={focusText}
              focusOutText={focusOutText}
            />
          </div>
          <button className="refresh" onClick={(e) => {convas.current.handleRefreshOutline()}}>
            <BiRefresh/>
          </button>
          <label htmlFor="import">
            <ImUpload2 /> Importer Image
          </label>
          <input id="import" type="file" onChange={(e) => {convas.current.handleChangeImage(e)}}/>
        </div>
        <div className="options">
          <button className="form" onClick={handleForme}>
            <h5>Forme</h5>
            <p>{listFormes[form][0]}</p>
            <img src={`./assets/images/${listFormes[form][1]}`} alt={listFormes[form][0]}/>
          </button>
          <button className="height" onClick={handleHeight}>
            <h5>Hauteur</h5>
            <p>{height} cm</p>
          </button>
          <button className="width" onClick={handleWidth}>
            <h5>Largeur</h5>
            <p>{width} cm</p>
          </button>
          <button className="quantity">
            <h5>Quantité</h5>
            <div>
              <IoMdAddCircle className="iconQty" onClick={handleAdd}/>
              <IoMdRemoveCircle className="iconQty" onClick={handleRemove}/>
              <p onClick={handleQuantity}>{quantity}</p>
            </div>
          </button>
          <button className="text" onClick={handleText}>
            <h5>Insérer<br/>Texte</h5>
            <BiText/>
          </button>
          <button className="background" onClick={handleColor}>
            <h5>Couleur<br/>Fond</h5>
            <div className="myColor" style={{backgroundColor: color}}></div>
          </button>
          <button className="image" onClick={() => Toggle()}>
            <h5>Librairie<br/>d'image</h5>
            <FaGrinStars/>
          </button>
          <button className="basket" onClick={(e) => convas.current.handleDownload(e, 'pdf')}>
            <h5>Ajouter<br/>au panier</h5>
            <AiFillSave/>
          </button>
          { /*
            <button className="pdf" onClick={(e) => convas.current.handleDownload(e, 'pdf')}>
              <h5>Télécharger</h5>
            </button>
          */}
        </div>
      </div>
      )
      }
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
