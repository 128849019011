import { traceImage, getSVG } from './potrace/index'

export default async function makePath(src, width, outLineSize) {
    var image = new window.Image();
    image.src = src
    image.crossOrigin = "Anonymous";
    let imgpromise = onload2promise(image);
    await imgpromise;
    var trace = traceImage(image)
    var svg = getSVG([trace[1]], 1, "curve", width, width, outLineSize)
    return svg
}

function onload2promise(obj){
    return new Promise((resolve, reject) => {
        obj.onload = () => resolve(obj);
        obj.onerror = reject;
    });
}
