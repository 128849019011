import React from 'react';
import { Text, Transformer, Image } from 'react-konva';
import useImage from 'use-image';


export default React.forwardRef((props, ref) => {
  const textRef = React.useRef(null)
  const transformer = React.useRef(null)
  const image = React.useRef(null)
  const [xPos, setxPos] = React.useState(null)
  const [mySvG] = useImage('./assets/images/remove.png');

  React.useImperativeHandle(ref, () => ({
    handleHidden(e) {
      transformer.current.hide()
    },
  }))
  React.useEffect(() => {
    if (props.hiddenSelect){
      transformer.current.hide()
    }
    var out = true
    transformer.current.nodes([textRef.current])
    transformer.current.add(image.current)
    image.current.on('click touchstart', function () {
      props.deleteMe(props.index)
    })
    setxPos(transformer.current.getWidth())
    textRef.current.on('scaleXChange', function () {
        setxPos(transformer.current.getWidth())
    });
    textRef.current.on('click touchstart', function () {
        props.focusText(props.index);
    });

    textRef.current.on('mouseout touchend', function () {
        out = true
    });
    textRef.current.on('mouseover touchstart', function () {
        out = false
    });
    props.stage.current.on('touchend', function () {
      out = true
    });
    props.stage.current.on('click touchstart', function () {
      if (out && transformer.current){
        transformer.current.hide()
        props.focusOutText()
      } else if (transformer.current) {
        transformer.current.show()
      }
    });
    textRef.current.on('dblclick dbltap', () => {
        // hide text node and transformer:
        textRef.current.hide();
        transformer.current.hide();

        // create textarea over canvas with absolute position
        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        var textPosition = textRef.current.absolutePosition();

        // so position of textarea will be the sum of positions above:
        var areaPosition = {
          x: props.stage.current.container().offsetLeft + textPosition.x,
          y: props.stage.current.container().offsetTop + textPosition.y,
        };
        // create textarea and style it
        var textarea = document.createElement('textarea');
        document.querySelector('.myCanvas').appendChild(textarea);

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = textRef.current.text();
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = textRef.current.width() - textRef.current.padding() * 2 + 'px';
        textarea.style.height =
        textRef.current.height() - textRef.current.padding() * 2 + 5 + 'px';
        textarea.style.fontSize = textRef.current.fontSize() + 'px';
        textarea.style.border = 'none';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = textRef.current.lineHeight();
        textarea.style.fontFamily = textRef.current.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = textRef.current.align();
        textarea.style.color = textRef.current.fill();
        var rotation = textRef.current.rotation();
        var transform = '';
        if (rotation) {
          transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(textRef.current.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';
        transform += 'scale('+textRef.current.scale().x+')';
        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = textarea.scrollHeight + 3 + 'px';

        textarea.focus();

        function removeTextarea() {
          textarea.parentNode.removeChild(textarea);
          window.removeEventListener('click', handleOutsideClick);
          textRef.current.show();
          transformer.current.show();
          transformer.current.forceUpdate();
        }

        function setTextareaWidth(newWidth) {
          if (!newWidth) {
            // set width for placeholder
            newWidth = textRef.current.placeholder.length * textRef.current.fontSize();
          }
          // some extra fixes on different browsers
          var isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          var isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isSafari || isFirefox) {
            newWidth = Math.ceil(newWidth);
          }

          var isEdge =
            document.documentMode || /Edge/.test(navigator.userAgent);
          if (isEdge) {
            newWidth += 1;
          }
          textarea.style.width = newWidth + 'px';
        }

        textarea.addEventListener('keydown', function (e) {
          // hide on enter
          // but don't hide on shift + enter
          // if (e.keyCode === 13 && !e.shiftKey) {
          //   textRef.current.text(textarea.value);
          //   removeTextarea();
          // }
          // on esc do not set value back to node
          if (e.keyCode === 27) {
            removeTextarea();
          }
        });

        textarea.addEventListener('keydown', function (e) {
          var scale = textRef.current.getAbsoluteScale().x;
          setTextareaWidth(textRef.current.width() * scale);
          textarea.style.height = 'auto';
          textarea.style.height =
            textarea.scrollHeight + textRef.current.fontSize() + 'px';
        });

        function handleOutsideClick(e) {
          if (e.target !== textarea) {
            textRef.current.text(textarea.value);
            removeTextarea();
          }
        }
        setTimeout(() => {
          window.addEventListener('click', handleOutsideClick);
        });
      });
  }, [props]);
  return (
        <>
          <Text
            ref={textRef}
            x={props.x || props.stage.current.container().offsetWidth / 2 - 50}
            y={props.y || props.stage.current.container().offsetHeight / 2}
            fontSize={30}
            fontFamily={props.fontFamily}
            text={props.text}
            draggable={true}
            keepRatio={true}
            fill={props.colorText}
            />
          <Transformer
            ref={transformer}
            keepRatio={true}
            enabledAnchors={[
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ]}
          />

          <Image
            x={xPos}
            y={-30}
            width={20}
            height={20}
            image={mySvG}
            ref={image}
          />

        </>
  )
})
