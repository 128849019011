const utilsGl = {
    clear() {
        console.log('todo');
    },
    drawImage() {
        console.log('todo');
    }
};
const utils2d = {
    clear(ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    },
    drawImage(ctx, image, x, y) {
        ctx.drawImage(image, x, y);
    }
};
export const utils = {
    clear(ctx) {
        if (ctx instanceof CanvasRenderingContext2D) {
            utils2d.clear(ctx);
        }
        else if (ctx instanceof WebGLRenderingContext) {
            utilsGl.clear(ctx);
        }
    },
    drawImage(ctx, image, x, y, option) {
        if (ctx instanceof CanvasRenderingContext2D) {
            utils2d.drawImage(ctx, image, x, y);
        }
        else if (ctx instanceof WebGLRenderingContext) {
            utilsGl.drawImage(ctx, image, x, y);
        }
        if (option === "onlyContour"){
          const { width, height } = ctx.canvas;
          const data = ctx.getImageData(0, 0, width, height);
          for (let i = 0; i < data.data.length; i += 4) {
              if (data.data[i+0] === 255 && data.data[i+1] === 0 && data.data[i+2] === 255) {
                data.data[i+3] = 255
              } else {
                data.data[i+3] = 0
              }
          }
          ctx.putImageData(data, 0, 0);
        }
        if (option === "ext"){
          const { width, height } = ctx.canvas;
          const data = ctx.getImageData(0, 0, width, height);
          for (let i = 0; i < data.data.length; i += 4) {
              if (data.data[i+3] === 255) {
                data.data[i+3] = 0
              } else {
                data.data[i+0] = 255
                data.data[i+1] = 245
                data.data[i+2] = 255
                data.data[i+3] = 255
              }
          }
          ctx.putImageData(data, 0, 0);
        }
        if (option === "removeWhite"){
          const { width, height } = ctx.canvas;
          const data = ctx.getImageData(0, 0, width, height);
          for (let i = 0; i < data.data.length; i += 4) {
              if (data.data[i+3] > 150) {
                data.data[i+3] = data.data[i+3]
              }else if (data.data[i+3] < 150) {
                data.data[i+3] = 0
              }
              if (data.data[i+0] === 255 && data.data[i+1] === 255 && data.data[i+2] === 255) {
                data.data[i+3] = 0
              }
          }
          ctx.putImageData(data, 0, 0);
        }
        if (option === "colorin"){
          const { width, height } = ctx.canvas;
          const data = ctx.getImageData(0, 0, width, height);
          for (let i = 0; i < data.data.length; i += 4) {
              if (data.data[i+3] > 150) {
                data.data[i+0] = 255
                data.data[i+1] = 255
                data.data[i+2] = 255
              }
          }
          ctx.putImageData(data, 0, 0);
        }
    }
};
