import React from 'react';
import { Transformer, Image } from 'react-konva';
import useImage from 'use-image';

export default React.forwardRef((props, ref) => {
  const imageRef = React.useRef(null)
  const transformer = React.useRef(null)
  const image = React.useRef(null)
  const [xPos, setxPos] = React.useState(null)
  const [mySvG] = useImage('./assets/images/remove.png');

  const [myImage] = useImage(props.url)

  React.useImperativeHandle(ref, () => ({
    handleHidden(e) {
      transformer.current.hide()
    },
  }))
  React.useEffect(() => {
    if (myImage){
      myImage.crossOrigin = 'Anonymous';
    }
  }, [myImage])

  React.useEffect(() => {
    if (props.hiddenSelect){
      transformer.current.hide()
    }
    var out = true
    transformer.current.nodes([imageRef.current])
    transformer.current.add(image.current)
    image.current.on('click touchstart', function () {
      props.deleteMeImage(props.index)
    })
    setxPos(transformer.current.getWidth())
    imageRef.current.on('scaleXChange', function () {
        setxPos(transformer.current.getWidth())
    });

    imageRef.current.on('mouseout touchend', function () {
        out = true
    });
    imageRef.current.on('mouseover touchstart', function () {
        out = false
    });
    props.stage.current.on('touchend', function () {
      out = true
    });
    props.stage.current.on('click touchstart', function () {
      if (out && transformer.current){
        transformer.current.hide()
      } else if (transformer.current) {
        transformer.current.show()
      }
    });
  }, [props]);
  return (
        <>
          <Image
            ref={imageRef}
            x={props.x || props.stage.current.container().offsetWidth / 2 - 50}
            y={props.y || props.stage.current.container().offsetHeight / 2}
            image={myImage}
            width={100}
            height={100}
            draggable={true}
            keepRatio={true}
            onDragEnd={e => {
              props.moveImage({
                index: props.index,
                x: e.target.x(),
                y: e.target.y()
              });
            }}
            />
          <Transformer
            ref={transformer}
            keepRatio={true}
            enabledAnchors={[
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ]}
          />

          <Image
            x={xPos}
            y={-30}
            width={20}
            height={20}
            image={mySvG}
            ref={image}
          />

        </>
  )
})
