import React from 'react';
import { Stage, Layer, Image, Rect, Ellipse, Transformer } from 'react-konva';
import { jsPDF } from 'jspdf';
import { SpinnerComponent } from 'react-element-spinner';
import 'svg2pdf.js';
import ImageStroke from './lib/index'
import rotate from './lib/method-rotate'
import contour from './lib/method-contour'
import makePath from './lib/contour-svg'
import TextKanvas from './components/text'
import ImageKanvas from './components/images'
import removeImageBlanks from './lib/trim-image'
import useImage from 'use-image';
const sizeBorder = [25, 50, 75, 75, 75]

export default React.forwardRef((props, ref) => {
  const mainImage = React.useRef(null)
  const [image, setImage] = React.useState(null)
  const [imageExt, setImageExt] = React.useState(null)
  const [stageSize, setStageSize] = React.useState([0,0])
  const [imageSize, setImageSize] = React.useState([0,0])
  const [margin, setMargin] = React.useState([0,0])
  const [isActiveLoader, setIsActiveLoader] = React.useState(false)
  const [sizeOutline, setSizeOutline] = React.useState(0)
  const [finalOutlineSVG, setFinalOutlineSVG] = React.useState(null)
  const [hiddenOutline, setHiddenOutline] = React.useState(true)
  const [hiddenSelect, setHiddenSelect] = React.useState(false)
  const containCanvas = props.containCanvas
  const stage = React.useRef(null)
  const stage2 = React.useRef(null)
  const layer = React.useRef(null)
  const transformer = React.useRef(null)
  const [transparent] = useImage("./assets/images/transparent.jpg")
  const imageStroke = new ImageStroke()
  imageStroke.use(rotate)
  React.useImperativeHandle(ref, () => ({
    handleChangeImage(e) {
      generateBorder()
    },
    handleDownload(e, format) {
      setHiddenOutline(false)
      setHiddenSelect(true)
      if (transformer.current){
        transformer.current.hide()
      }
      setTimeout( async function () {
        let image = stage.current.toDataURL();
        var myImage = new window.Image();
        myImage.src = image
        await onload2promise(myImage);
        var myrimage = removeImageBlanks(stage.current.content.children[0]).toDataURL();
        let doc = undefined
        if (props.ratio[1] === "height"){
          doc = new jsPDF('l', 'cm', [props.width, props.height])
        } else {
          doc = new jsPDF('p', 'cm', [props.width, props.height])
        }
        var sizeOutlineInCentimeter =  sizeOutline * 0.0264583333
        var height = 0
        var width = 0
        var heightMargin = 0
        var widthMargin = 0
        if (props.ratio[1] !== "height"){
          height = props.height-sizeOutlineInCentimeter/4
          width = height * props.ratio[0]
          heightMargin = sizeOutlineInCentimeter/8
          widthMargin = heightMargin * props.ratio[0]
        } else {
          width = props.width-sizeOutlineInCentimeter/4
          height = width * props.ratio[0]
          widthMargin = sizeOutlineInCentimeter/8
          heightMargin = widthMargin * props.ratio[0]
        }
        var c = document.createElement("canvas");
        c.width = 1000;
        c.height = 1000;
        var ctx = c.getContext("2d");
        ctx.fillStyle = props.color;
        ctx.fillRect(0, 0, 1000, 1000);
        var background = c.toDataURL();
        doc.addImage(background, 'png', 0, 0, props.width, props.height);
        doc.addImage(myrimage, 'png', widthMargin, heightMargin, width, height);
        var svg = document.querySelector('#svgContent svg')
        var copySvg = svg.cloneNode(true)
        var bbox = svg.getBBox();
        var viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
        copySvg.setAttribute("viewBox", viewBox);
        doc
          .svg(copySvg, {
            x: 0,
            y: 0,
            width: Number(props.width),
            height: Number(props.height)
          })
          .then(() => {
            window.open(doc.output('bloburl'), '_blank');
            // doc.save('myPDF.pdf')
            setHiddenSelect(false)
            setHiddenOutline(true)
          })
      }, 10);
    },
    handleChangeForm(e) {
      generateBorder()
    },
    handleRefreshOutline() {
      if (document.querySelector('#import').files[0]) {
        generateBorder()
      }
    }
  }))
  const handleDeleteMe = (i) => {
    props.handleDeleteMe(i)
  }
  const focusOutText = ()=>{
    props.focusOutText()
  }
  const focusText = (i) => {
    props.focusText(i)
  }
  const deleteMeImage = (i) => {
    props.handleDeleteMeImage(i)
  }
  const moveImage = (data) => {
    props.moveImage(data)
  }
  const generateBorder = () => {
    document.querySelector('canvas').width = containCanvas.current.offsetWidth
    document.querySelector('canvas').height = containCanvas.current.offsetHeight
    setStageSize([containCanvas.current.offsetWidth, containCanvas.current.offsetHeight])
    if (transformer.current){
      transformer.current.hide()
    }
    setHiddenSelect(true)
    if (stage.current) {
      setImage(null)
      setIsActiveLoader(true)
      setFinalOutlineSVG(null)
      var reader = new FileReader();
      if (document.querySelector('#import').files[0]){
        reader.readAsDataURL(document.querySelector('#import').files[0]);
        reader.onloadend = function async (e) {
          var extention = document.querySelector('#import').files[0].name.split('.')[1]
          if (extention === 'ai' || extention === 'psd' || extention === 'pdf' || extention === 'eps' ||
              extention === 'AI' || extention === 'PSD' || extention === 'PDF' || extention === 'EPS'){
            let formData = new FormData();
            formData.append('files', document.querySelector('#import').files[0]);
            fetch(window.ENV.URL_API_loadImage, {method: 'POST', body: formData})
              .then((response) => response.json())
              .then((json) => {
                if (json.status === 200){
                  getRatio('data:image/png;base64,'+json.base64, "true")
                }
              })
              .catch((error) => console.error(error))
          }
          getRatio(URL.createObjectURL(document.querySelector('#import').files[0]), "true")
        }
      }else if (props.form >= 3){
        setImageSize([containCanvas.current.offsetHeight/2, containCanvas.current.offsetWidth/2])
        setMargin([containCanvas.current.offsetHeight/4, containCanvas.current.offsetWidth/4])
        props.setHeight(2.8)
        props.setWidth(3)
        props.setRatio([1, 'width'])
        setBorder()
      } else {
        setIsActiveLoader(false)
      }
    }
    function getRatio(src, rmWhite){
      var myImage = new window.Image();
      myImage.src = src
      // setImage(myImage)
      myImage.onload = () =>{
        if (rmWhite === 'true') {
          const resultCanvas = imageStroke.make(myImage, {
              thickness: 0,
              color: 'rgba(#ffffff, 0)',
              onlyContour: "removeWhite"
          })
          var myImageRmBackground = new window.Image();
          myImageRmBackground.src = resultCanvas.toDataURL("image/png")
          myImageRmBackground.onload = () =>{
            setImage(myImageRmBackground)
            setBorder()
          }
        }
        if (myImage.width > myImage.height){
          const ratio = myImage.height / myImage.width
          const maxWidth = containCanvas.current.offsetWidth - (0.4 * containCanvas.current.offsetWidth)
          let width = 0
          let height = 0
          width = maxWidth
          height = maxWidth * ratio
          let marginTop = (containCanvas.current.offsetHeight - height) / 2
          let marginLeft = (containCanvas.current.offsetWidth - width) / 2
          if (rmWhite === 'true') {
            setImageSize([height, width])
            setMargin([marginTop, marginLeft])
          }
          props.setWidth(props.minSize)
          props.setHeight(Number(props.minSize * ratio).toFixed(1))
          props.setRatio([ratio, 'height'])
        } else {
          const ratio = myImage.width / myImage.height
          const maxHeight = containCanvas.current.offsetHeight - (0.4 * containCanvas.current.offsetHeight)
          let width = 0
          let height = 0
          height = maxHeight
          width = maxHeight * ratio
          let marginTop = (containCanvas.current.offsetHeight - height) / 2
          let marginLeft = (containCanvas.current.offsetWidth - width) / 2
          if (rmWhite === 'true') {
            setImageSize([height, width])
            setMargin([marginTop, marginLeft])
          }
          props.setHeight(props.minSize)
          props.setWidth(Number(props.minSize * ratio).toFixed(1))
          props.setRatio([ratio, 'width'])
        }
      };
    }
    async function setBorder(myImage) {
      var outLineSize = 0
      var myImage0 = new window.Image();
      myImage0.src = stage.current.toDataURL()
      await onload2promise(myImage0);
      let myImage2
      if (props.form < 3) {
        var mysizeBorder = sizeBorder[props.form]
        if (!props.sizeScreen){
          mysizeBorder = sizeBorder[props.form] / 3
        }
        const resultCanvas = imageStroke.make(myImage0, {
            thickness: mysizeBorder/2,
            color: 'grey',
            onlyContour: "colorin"
        })
        outLineSize += mysizeBorder
        myImage2 = new window.Image();
        myImage2.src = resultCanvas.toDataURL("image/png")
        imageStroke.use(contour)
        await onload2promise(myImage2);
        myImage2.src = imageStroke.make(myImage2, {
            thickness: mysizeBorder/2,
            color: 'white',
        }).toDataURL("image/png")
        imageStroke.use(rotate)
        outLineSize += 2
      } else {
        myImage2 = new window.Image();
        myImage2.src = stage.current.toDataURL()
      }
      await onload2promise(myImage2);
      const ext = imageStroke.make(myImage2, {
          thickness: 5,
          color: 'magenta',
          onlyContour: "ext"
      })
      makePath(myImage2.src, containCanvas.current.offsetWidth, outLineSize).then(value => {
        var myImage6 = new window.Image();
        document.querySelector('#svgContent').innerHTML = value
        myImage6.src = `data:image/svg+xml;utf8,${value}`
        myImage6.onload = () =>{
          setFinalOutlineSVG(myImage6)
        }
      })
      var myImage4 = new window.Image();
      myImage4.src = ext.toDataURL("image/png")
      myImage4.onload = () =>{
        setImageExt(myImage4)
      }
      // setFinalOutlineSVG(myImage3)
      setSizeOutline(outLineSize)
      if (transformer.current){
        transformer.current.show()
      }
      var canvas = document.createElement("canvas");
      canvas.width = myImage2.width;
      canvas.height = myImage2.height;
      var context = canvas.getContext("2d");
      context.drawImage(myImage2, 0, 0);
      var myrimage = removeImageBlanks(canvas).toDataURL("image/png")
      getRatio(myrimage, 'false')
      setHiddenSelect(false)
      setIsActiveLoader(false)
    }
  }

  function onload2promise(obj){
      return new Promise((resolve, reject) => {
          obj.onload = () => resolve(obj);
          obj.onerror = reject;
      });
  }
  React.useEffect(() => {
    document.querySelector('canvas').width = containCanvas.current.offsetWidth
    document.querySelector('canvas').height = containCanvas.current.offsetHeight
    setStageSize([containCanvas.current.offsetWidth, containCanvas.current.offsetHeight])
  }, [setStageSize, containCanvas]);

  React.useEffect(() => {
    setTimeout(function () {
      generateBorder()
    }, 50);
  }, []);

  React.useEffect(() => {
    if (image !== null){
      var out = true
      transformer.current.nodes([mainImage.current])
      transformer.current.hide()
      mainImage.current.on('mouseout touchend', function () {
          out = true
      });
      mainImage.current.on('mouseover touchstart', function () {
          out = false
      });
      stage.current.on('touchend', function () {
        out = true
      });
      stage.current.on('click touchstart', function () {
        if (out){
          transformer.current.hide()
        } else {
          transformer.current.show()
        }
      });
    }
  }, [transformer, image])


  return (
    <>

    <SpinnerComponent loading={isActiveLoader} />
    <div id='svgContent' style={{zIndex: 10, pointerEvents: "none"}}></div>
    <Stage ref={stage} width={stageSize[0]} height={stageSize[1]}>
      <Layer ref={layer}>
        {
          props.form === 3 ?
          <Rect
            width={imageSize[1]}
            height={imageSize[0]}
            x={margin[1]}
            y={margin[0]}
            fill={props.color}
          />
        : null
        }
        {
          props.form === 4 ?
          <Ellipse
            x={margin[1]+imageSize[1]/2}
            y={margin[0]+imageSize[0]/2}
            width={imageSize[1]*1.4}
            height={imageSize[0]*1.5}
            fill={props.color}
          />
        : null
        }
        {
          props.form === 5 ?
          <Rect
            width={imageSize[1]+66}
            height={imageSize[0]+66}
            x={margin[1]-33}
            y={margin[0]-33}
            cornerRadius={50}
            fill={props.color}
          />
        : null
        }
        {
          props.form < 3 && finalOutlineSVG && hiddenOutline ?
          <Rect
            width={5000}
            height={5000}
            x={0}
            y={0}
            stroke={"white"}
            fill={props.color}
          />
        : null
        }
        {
          transparent && props.materials === 1 && finalOutlineSVG && hiddenOutline ?
            <Image
              x={0}
              y={0}
              width={2000}
              height={2000}
              image={transparent}
            />
          : null
        }

        {
          image ?
            <>
              <Image
                width={imageSize[1]}
                height={imageSize[0]}
                x={margin[1]}
                y={margin[0]}
                image={image}
                ref={mainImage}
                draggable
              />
              <Transformer
                ref={transformer}
                keepRatio={true}
                enabledAnchors={[
                  'top-left',
                  'top-right',
                  'bottom-left',
                  'bottom-right',
                ]}
              />
            </>
          : null
        }
        {
          imageExt ?
            props.allImages.map((item, i) => {
              if (item !== null){
                return <ImageKanvas
                  key={i}
                  index={i}
                  x={item.x}
                  y={item.y}
                  stage={stage}
                  url={item.src}
                  deleteMeImage={deleteMeImage}
                  moveImage={moveImage}
                  hiddenSelect={hiddenSelect}
                  />
              }
            })
          : null
        }
        {
          props.allText.map((item, i) => {
            if (item !== null){
              return <TextKanvas
                key={i}
                index={i}
                stage={stage}
                text={item.text}
                colorText={item.color}
                fontFamily={item.font}
                deleteMe={handleDeleteMe}
                focusText={focusText}
                focusOutText={focusOutText}
                hiddenSelect={hiddenSelect}
              />
            }
          })
        }
      </Layer>
    </Stage>
    <Stage ref={stage2} width={stageSize[0]} height={stageSize[1]} style={{pointerEvents: "none", opacity: 0.95}}>
      <Layer>
        {
          imageExt ?
            <Image
              x={-sizeOutline-6}
              y={-sizeOutline-6}
              image={imageExt}
            />
          : null
        }
      </Layer>
    </Stage>
    </>
  )
})
